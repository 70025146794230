/* original file: src\assets\js\script-loader-app.js */
/**
 * Script loading retry mechanism with timeout
 *
 * This addresses the issue where the script-loader.js file is not loaded in time, resulting in the error: "window.loadScripts is not a function."
 * The error was only encountered during Cypress testing on Windows in a local environment. It is not stable to reproduce, failure rate is less than 5%.
 *
 */
(function checkAndLoadApp() {
    const startTime = Date.now();
    const TIMEOUT_MS = 5000; // 5 seconds timeout
    const RETRY_INTERVAL = 10; // retry every 10ms

    function showError(message) {
        console.error('script-loader-app.js => attemptLoad => ', message);
        alert(`${message}\n\nPlease refresh the page to try again.`);
    }

    function attemptLoad() {
        // Check timeout first
        if (Date.now() - startTime > TIMEOUT_MS) {
            showError('Script loader timeout: Failed to load app after 5 seconds');
            return;
        }

        // Check if window.loadScripts exists and is a function
        if (window.loadScripts && typeof window.loadScripts === 'function') {
            try {
                window.loadScripts('app');
            } catch (error) {
                showError(`Failed to load scripts: ${error.message}`);
            }
        } else {
            // Not ready yet, retry after interval
            setTimeout(attemptLoad, RETRY_INTERVAL);
        }
    }

    // Start the loading attempt
    attemptLoad();
})();
